<template>
    <v-container>

        <v-row>
            <v-col cols="12" sm="12" class="px-0 ">
                <v-card  elevation="1" class="px-5" outlined >
                    <div class="px-5">
                        <v-toolbar flat class="">
                            <v-tabs v-model="typeTab" class="d-flex justify-center">
                                <v-tab>
                                    Client
                                </v-tab>
                                <v-tab>
                                    Internal
                                </v-tab>
                            </v-tabs>
                        </v-toolbar>

                        <v-tabs-items v-model="typeTab">
                            <v-tab-item>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                    <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Number of Unique Visitors per Month - Filter Years
                                    </v-card-title>
                                    <div class="px-5">
                                        <PageUniqueVisitorsChart status="client"/>

                                    </div>
                                </v-card>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                    <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Number of Unique Visitors per Month by Platform - Filter Years
                                    </v-card-title>
                                    <div class="px-5">
                                        <PageUniqueVisitorsPlatformChart status="client"/>

                                    </div>
                                </v-card>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                        <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Number of Logins per Month - Filter Years
                                    </v-card-title>
                                    <div class="px-5">
                                        <PageLoginChart status="client"/>

                                    </div>
                                </v-card>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                    <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Number of Logins per Month by Platform - Filter Years
                                    </v-card-title>
                                    <div class="px-5">
                                        <PageLoginPlatformChart status="client"/>

                                    </div>
                                </v-card>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                    <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Top 5 Users - Filter Years
                                    </v-card-title>
                                    <div class="px-5">
                                        <PageTopUsersChart status="client"/>

                                    </div>
                                </v-card>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                    <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Login Analysis
                                    </v-card-title>
                                    <div>
                                        <PageLoginAnalysisTable status="client"/>

                                    </div>
                                </v-card>
                                <br>

                                <!-- <PageLoginAnalysisTable status="client"/> -->

                                
                                <!-- <ComponentTaskAssigned status="incomplete"/> -->
                            </v-tab-item>
                            <v-tab-item>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                    <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Number of Unique Visitors per Month - Filter Years
                                    </v-card-title>
                                    <div class="px-5">
                                        <PageUniqueVisitorsChart status="internal"/>

                                    </div>
                                </v-card>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                    <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Number of Unique Visitors per Month by Platform - Filter Years
                                    </v-card-title>
                                    <div class="px-5">
                                        <PageUniqueVisitorsPlatformChart status="internal"/>

                                    </div>
                                </v-card>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                        <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Number of Logins per Month - Filter Years
                                    </v-card-title>
                                    <div class="px-5">
                                        <PageLoginChart status="internal"/>

                                    </div>
                                </v-card>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                    <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Number of Logins per Month by Platform - Filter Years
                                    </v-card-title>
                                    <div class="px-5">
                                        <PageLoginPlatformChart status="internal"/>

                                    </div>
                                </v-card>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                    <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Top 5 Users - Filter Years
                                    </v-card-title>
                                    <div class="px-5">
                                        <PageTopUsersChart status="internal"/>

                                    </div>
                                </v-card>
                                <br>
                                <v-card class="px-5 py-2" outlined>
                                    <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                                        Login Analysis
                                    </v-card-title>
                                    <div>
                                        <PageLoginAnalysisTable status="internal"/>

                                    </div>
                                </v-card>
                                <br>
                                <!-- <PageCompaniesListSubscription status="Expired"/> -->
                                <!-- <PageLoginAnalysisTable status="internal"/> -->
                            </v-tab-item>
                        </v-tabs-items>


                    </div>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import PageTopUsersChart from '../analysis/PageTopUsersChart.vue';
import PageLoginChart from '../analysis/PageLoginChart.vue';
import PageLoginPlatformChart from '../analysis/PageLoginPlatformChart.vue';
import PageUniqueVisitorsChart from '../analysis/PageUniqueVisitorsChart.vue';
import PageUniqueVisitorsPlatformChart from '../analysis/PageUniqueVisitorsPlatformChart.vue';

import PageLoginAnalysisTable from '../analysis/PageLoginAnalysisTable.vue';
// import ComponentTaskAssigned from '../../components/task/ComponentTaskAssigned.vue';
// import PageCompaniesListSubscription from '../company/PageCompaniesListSubscription.vue';

export default{
    components: { PageTopUsersChart, PageLoginChart, PageLoginPlatformChart, PageUniqueVisitorsChart, PageUniqueVisitorsPlatformChart, PageLoginAnalysisTable
        // , ComponentTaskAssigned
     },

    methods:{

    },
    data:() => ({
        typeTab : null,
        task:null,
        userRole:null,
        isPending:false,
        searchResult:null,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
        }
    }),
   
}
</script>