<template>
    <v-container>
      <v-card flat>
        <!-- <v-card-title> -->
          <v-select dense outlined label="Years" class="py-0 my-0" :items="yearOptions" v-model="year" @change="fetch">
          </v-select>
        <!-- </v-card-title> -->
        <div>
          <v-card-text class="d-flex justify-center align-item ">
            <v-progress-circular v-if="api.isLoading" :size="50" color="primary" style="align-items: center;position:absolute" indeterminate>
            </v-progress-circular>
            <canvas v-show="!api.isLoading" ref="myChart">
    
            </canvas>
          </v-card-text>
        </div>
      </v-card>
    </v-container>
    </template>
  <script>
  import Chart from 'chart.js'
  
  export default {
    props:[
    'status'
    ],

    created(){
      this.api.callbackReset = () => {
          this.api.isLoading = true;
          this.api.isError = false;
      };
      this.api.callbackError = (e) => {
          this.api.isLoading = false;
          this.api.isError = true;
          this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
  
        const ctx = this.$refs.myChart.getContext('2d')

        if (this.chart) {
        // Destroy the existing chart instance
        this.chart.destroy();
      }
      
          let month = Object.keys(resp.web);
          let web = Object.values(resp.web);
  
          this.trenData = [
            {
              x:month,
              y:Object.values(resp.web),
            }
          ];
          this.trendData={
            datasets:[web]
          }
          let config = {
            type: 'bar',
            data: {
              labels: month,
              datasets: [
                {
                  label: 'app',
                  data: Object.values(resp.app),
                  borderWidth:1,
                  backgroundColor: '#43D1EF',
                  borderColor:'rgb(67, 209, 135)',
                  fill:false,
                },
                {
                  label: 'app browser',
                  data: Object.values(resp.appbrowser),
                  borderWidth:1,
                  backgroundColor: '#00C0EF',
                  borderColor:'rgb(0, 192, 239)',
                  fill:false,
                },
                {
                  label: 'desktop browser',
                  data: Object.values(resp.web),
                  borderWidth:1,
                  backgroundColor: '#F9616D',
                  borderColor:'rgb(249, 97, 109)',
                  fill:false,
                },
                {
                  label: 'unknown',
                  data: Object.values(resp.unknown),
                  borderWidth:1,
                  backgroundColor:'#F7BB4D',
                  borderColor: "rgb(247, 187, 77)",
                  fill:false,
                },
              ]
            },
            options: {
                scales: {
                    yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                    }
                    }]
                }
            },
          }
          this.chart = new Chart(ctx,config);
  
          this.api.isLoading = false;
          this.api.isError = false;
          this.isSucessful = true;
      }
    },
    mounted () {
      this.fetch();
    },
    data: () => ({
      api:{
          isSucessful:false,
          isLoading :false,
          isError:false,
          error:null,
          url:null,
      },
      yearOptions:['2021','2022','2023'],
      year:'2023',
      users:null,
      companies:null,
      videos:null,
      userWatches:null,
      trenData:null,
    }),
    methods:{
      fetch(){  
        let fetchPerformanceApi = this.fetchPerformance();
        this.$api.fetch(fetchPerformanceApi);
      },
      fetchPerformance(){
        let tempApi = this.$_.clone(this.api);
        tempApi.method = "GET";
        tempApi.url = process.env.VUE_APP_SERVER_API+"/analysis/uniquevisitorsplatform?year="+this.year+"&status="+this.status;
        return tempApi;
      },
    }
  }
  </script>